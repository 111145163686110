import { BASE_URL } from "./EndpointsUtils";
import { INTERNAL_SERVER_ERROR } from "../constants/ErrorMessages";
import { UserSessionUtils } from "./UserSessionUtils";

export class BaseApiService {
  apiEndpoint;
  authToken;
  requestHeaders = {};
  pageContext = null;

  /**
   * This is constructor is used to initialize the API service endpoint to be used for this call.
   *
   * @param apiEndpoint
   */
  constructor(apiEndpoint) {
    this.apiEndpoint = BASE_URL + apiEndpoint;
    this.authToken = UserSessionUtils.getBearerToken();
  }

  /**
   * This method is used to make a GET api request to the provided constructor API endpoint.
   *
   * @param queryParameters
   * @returns
   */
  async getRequest(queryParameters) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.authToken,
    };
    return fetch(
      this.apiEndpoint + "?" + new URLSearchParams(queryParameters),
      {
        method: "GET",
        headers: headers,
      }
    );
  }

  /**
   * This method is used to make a GET api request to the provided constructor API endpoint.
   * This returns a JSON response or redirects to the login screen if a 401 is detected.
   *
   * @param queryParameters
   * @returns
   */
  async getRequestWithJsonResponse(queryParameters) {
    return this.getRequest(queryParameters).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 400 || response.status === 500) {
        let data = response.json();
        let errorMessage = data?.message ?? INTERNAL_SERVER_ERROR;
        throw new TypeError(errorMessage);
      } else if (response.status === 401 || response.status === 403) {
        UserSessionUtils.clearLocalStorageAndLogout(this.pageContext);
      } else {
        throw new TypeError(INTERNAL_SERVER_ERROR);
      }
    });
  }

  /**
   * This method is used to make a POST API request to the provided constructor endpoint.
   *
   * @param requestBody
   * @returns
   */
  async postRequest(requestBody) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.authToken,
    };
    return fetch(this.apiEndpoint, {
      method: "POST",
      headers: headers,
      body: requestBody !== null ? JSON.stringify(requestBody) : "",
    });
  }

  /**
   * This method is used to make a POST API request to the provided constructor endpoint.
   * This returns a JSON response or redirects to the login screen if a 401 is detected.
   *
   * @param requestBody
   * @returns
   */
  async postRequestWithJsonResponse(requestBody) {
    const response = await this.postRequest(requestBody);
    if (response.ok) {
      return response.json();
    } else if (
      response.status === 400 ||
      response.status === 403 ||
      response.status === 500
    ) {
      let data = await response.json();
      console.log("Response>>>" + JSON.stringify(data));
      let errorMessage = data?.message ?? INTERNAL_SERVER_ERROR;
      throw new TypeError(errorMessage);
    } else if (response.status === 401) {
      UserSessionUtils.clearLocalStorageAndLogout();
    } else {
      throw new TypeError(INTERNAL_SERVER_ERROR);
    }
  }

  /**
   * This method is used to obtain a refresh token from the server
   */
  async refreshTokenRequest() {
    let requestBody = { token: UserSessionUtils.getRefreshToken() };
    return fetch(this.apiEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    });
  }

    /**
   * This method is used to make a POST API request to the provided constructor endpoint.
   *
   * @param formData
   * @returns
   */
    async postFormRequest(formData) {
      const headers = {
        'accept': '*/*',
        Authorization: "Bearer " + this.authToken,
      };
      console.log("Form data ", JSON.stringify(formData));
      return fetch(this.apiEndpoint, {
        method: "POST",
        headers: headers,
        body: formData,
      });
    }
  
    /**
     * This method is used to make a POST API request to the provided constructor endpoint.
     * This returns a JSON response or redirects to the login screen if a 401 is detected.
     *
     * @param formData
     * @returns
     */
    async postRequestWithFormResponse(formData) {
      const response = await this.postFormRequest(formData);
      if (response.ok) {
        return response.json();
      } else if (
        response.status === 400 ||
        response.status === 403 ||
        response.status === 500
      ) {
        let data = await response.json();
        console.log("Response>>>" + JSON.stringify(data));
        let errorMessage = data?.message ?? INTERNAL_SERVER_ERROR;
        throw new TypeError(errorMessage);
      } else if (response.status === 401) {
        UserSessionUtils.clearLocalStorageAndLogout();
      } else {
        throw new TypeError(INTERNAL_SERVER_ERROR);
      }
    }

}