let resolveBackendEndpoint = "http://localhost:3000";
let remoteResolveBackendEndpoint = "https://backend.learningking.academy/api/v1";
// let remoteResolveBackendEndpoint = "https://learningkingv2.us-east-1.elasticbeanstalk.com/api/v1"
// if (window.location.hostname === "localhost") {
//   resolveBackendEndpoint = "http://localhost:8080";
// } else {
//   resolveBackendEndpoint = "https://kacuba-bo.azurewebsites.net";
// }

export const BASE_URL = remoteResolveBackendEndpoint;
